import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalNumberToEventId',
})
export class ExternalNumberToEventIdPipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    value = value?.trim();
    if (!value) {
      return '';
    }
    const start = value.indexOf('#');
    const end = value.lastIndexOf('#');

    if (start !== -1 && end !== -1) {
      const newValue = value.substring(start + 1, end);
      const endIndex = newValue.lastIndexOf('-');

      if (endIndex === -1) {
        return value;
      }
      return newValue.substring(0, endIndex);
    }
    return value;
  }
}
