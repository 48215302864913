import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isToday',
})
export class IsTodayPipe implements PipeTransform {
  transform(value?: Date | string | number): boolean {
    if (!value) return false;

    const time = new Date(value);
    const timeToCheck: number = time.getTime();

    // 24 hours in milliseconds
    const interval = 1000 * 60 * 60 * 24;
    const startOfToday = Math.floor(Date.now() / interval) * interval;
    const endOfToday = startOfToday + interval - 1;

    return startOfToday < timeToCheck && timeToCheck < endOfToday;
  }
}
