import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plaintextForHtml',
})
export class PlaintextForHtmlPipe implements PipeTransform {
  transform(value: string | undefined) {
    const text: string = value?.trim() ?? '';
    if (!text) {
      return '';
    }

    return text./*replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "").*/ replace(/(?:\r\n|\r|\n)/g, '<br>') ?? '';
  }
}
